import { COOKIE_OPTIONS_DEFAULT, getImmutableCookie, removeCookie, setImmutableCookie } from '@dbh/cookies';
import { Map, get } from 'immutable';
import { COOKIE_KEYS } from '@dbh/cookie-keys';
import withConformsTo from '@dbh/with-conforms-to-for-production-www';
import PropTypes from 'prop-types';
import addDays from 'date-fns/addDays';
import _noop from 'lodash/noop';
import '@dbh/generic-types';
import 'react-immutable-proptypes';
import '@dbh/create-custom-react-prop-type';
import '@dbh/date-fns-prop-types';
import '@dbh/admindev-types';
import 'universal-cookie';

const NEVER_EXPIRE_COOKIE_OPTIONS=Object.freeze({...COOKIE_OPTIONS_DEFAULT,maxAge:"2137483647"});const EXPIRE_AFTER_30_DAYS_COOKIE_OPTIONS=Object.freeze({...COOKIE_OPTIONS_DEFAULT,maxAge:"2592000"});

const universalCookieExpressOrOtherCookiePropType=_noop;var universalCookieExpressOrOtherCookiePropType$1 = universalCookieExpressOrOtherCookiePropType;

/**
 * Returns the "anonymous ID" cookie, that attempts to uniquely identify an user
 * that is not signed in, since it "never expires" until the browser's storage
 * is cleared.
 * @param {Object} cookies An instance of `cookies`.
 * @return {string?} The value.
 */const getImmutableCookieAnonymousId=withConformsTo("getImmutableCookieAnonymousId",[],a=>{const b=getImmutableCookie(a,COOKIE_KEYS.ANONYMOUS_ID),c=Map.isMap(b)?b.get("value"):b;// The old cookie format was a string, so we have to check here.
return c||void 0});var getImmutableCookieAnonymousId$1 = getImmutableCookieAnonymousId;

/**
 * Removes the "anonymous ID" cookie.
 * @param {Object} cookies An instance of `cookies`.
 */const removeImmutableCookieAnonymousId=withConformsTo("removeImmutableCookieAnonymousId",[],a=>{removeCookie(a,COOKIE_KEYS.ANONYMOUS_ID);});var removeImmutableCookieAnonymousId$1 = removeImmutableCookieAnonymousId;

/**
 * Sets the "anonymous ID" cookie.
 * @param {Object} cookies An instance of `cookies`.
 * @param {string} value A `GUID`.
 */const setImmutableCookieAnonymousId=withConformsTo("setImmutableCookieAnonymousId",[],(a,b)=>{setImmutableCookie(a,COOKIE_KEYS.ANONYMOUS_ID,b,NEVER_EXPIRE_COOKIE_OPTIONS);});var setImmutableCookieAnonymousId$1 = setImmutableCookieAnonymousId;

/**
 * Returns the "GUID" session cookie: it is a "session cookie" with no expiration
 * date set (default options). It is expected to be cleared by the browser when
 * it is closed, or when the browser's storage is cleared.
 * @param {Object} cookies An instance of `cookies`.
 * @return {string?} The value.
 */const getImmutableCookieGuidSession=withConformsTo("getImmutableCookieGuidSession",[],a=>get(getImmutableCookie(a,COOKIE_KEYS.GUID_SESSION),"value"));var getImmutableCookieGuidSession$1 = getImmutableCookieGuidSession;

/**
 * Sets the "GUID" session cookie.
 * @param {Object} cookies An instance of `cookies`.
 * @param {string} value A `GUID`.
 */const setImmutableCookieGuidSession=withConformsTo("setImmutableCookieGuidSession",[],(a,b)=>{setImmutableCookie(a,COOKIE_KEYS.GUID_SESSION,b);});var setImmutableCookieGuidSession$1 = setImmutableCookieGuidSession;

/**
 * Removes the `guidSession` cookie.
 * @param {Object} cookies An instance of `cookies`.
 */const removeImmutableCookieGuidSession=withConformsTo("removeImmutableCookieGuidSession",[],a=>{removeCookie(a,COOKIE_KEYS.GUID_SESSION);});var removeImmutableCookieGuidSession$1 = removeImmutableCookieGuidSession;

/**
 * Returns the `utm` cookie.
 * @param {Object} cookies An instance of `cookies`.
 * @return {Immutable.Map?} The `utm` payload, if valid.
 */const getImmutableUtmCookie=withConformsTo("getImmutableCookieGuidSession",[],a=>{const b=get(getImmutableCookie(a,COOKIE_KEYS.UTM),"value");return Map.isMap(b)?b:void 0});var getImmutableUtmCookie$1 = getImmutableUtmCookie;

/**
 * Removes the `utm` cookie.
 * @param {Object} cookies An instance of `cookies`.
 */const removeImmutableUtmCookie=withConformsTo("removeImmutableUtmCookie",[],a=>{removeCookie(a,COOKIE_KEYS.UTM);});var removeImmutableUtmCookie$1 = removeImmutableUtmCookie;

/*
 *
 * Constants: `utmCookie`.
 *
 */const CURRENT_UTM_COOKIE_VERSION=1;const UTM_COOKIE_SET_BY={browser:"browser",ssr:"ssr",cfWorker:"cf-worker"};

const utmCookieShape={};var utmCookieShape$1 = utmCookieShape;

const utmCookieImmutablePropType=_noop;var utmCookieImmutablePropType$1 = utmCookieImmutablePropType;

const utmCookiePropType=_noop;var utmCookiePropType$1 = utmCookiePropType;

({...utmCookieShape$1,utmCookieSetBy:PropTypes.oneOf(Object.values(UTM_COOKIE_SET_BY)).isRequired});const setImmutableUtmCookieImmutablePropType=_noop;var setImmutableUtmCookieImmutablePropType$1 = setImmutableUtmCookieImmutablePropType;

/**
 * Returns the given "UTM" cookie decorated with dates.
 * @param {Immutable.Map|Object} utmCookieValue .
 * @see {@link https://test.daybreakhotels.com/IT/it-IT?utm_source=staff&utm_medium=lorem&utm_campaign=ipsum}
 */const decorateUtmCookie=withConformsTo("buildUtmCookie",[],a=>{const b=new Date,{maxAge:c}=EXPIRE_AFTER_30_DAYS_COOKIE_OPTIONS,d=b.toISOString(),e=addDays(b,c/86400).toISOString(),f={utmCookieCreationDate:d,utmCookieExpirationDate:e,utmCookieVersion:CURRENT_UTM_COOKIE_VERSION};return Map.isMap(a)?a.merge(f):{...a,...f}});var decorateUtmCookie$1 = decorateUtmCookie;

/**
 * Sets our custom "utm" cookie.
 * @param {Object} cookies An instance of `cookies`.
 * @param {Immutable.Map} value The `utm` cookie payload.
 * @see {@link https://test.daybreakhotels.com/IT/it-IT?utm_source=staff&utm_medium=lorem&utm_campaign=ipsum}
 */const setImmutableUtmCookie=withConformsTo("setImmutableUtmCookie",[],(a,b)=>{const c=decorateUtmCookie$1(b);setImmutableCookie(a,COOKIE_KEYS.UTM,c,EXPIRE_AFTER_30_DAYS_COOKIE_OPTIONS);});var setImmutableUtmCookie$1 = setImmutableUtmCookie;

export { EXPIRE_AFTER_30_DAYS_COOKIE_OPTIONS, NEVER_EXPIRE_COOKIE_OPTIONS, decorateUtmCookie$1 as decorateUtmCookie, getImmutableCookieAnonymousId$1 as getImmutableCookieAnonymousId, getImmutableCookieGuidSession$1 as getImmutableCookieGuidSession, getImmutableUtmCookie$1 as getImmutableUtmCookie, removeImmutableCookieAnonymousId$1 as removeImmutableCookieAnonymousId, removeImmutableCookieGuidSession$1 as removeImmutableCookieGuidSession, removeImmutableUtmCookie$1 as removeImmutableUtmCookie, setImmutableCookieAnonymousId$1 as setImmutableCookieAnonymousId, setImmutableCookieGuidSession$1 as setImmutableCookieGuidSession, setImmutableUtmCookie$1 as setImmutableUtmCookie, setImmutableUtmCookieImmutablePropType$1 as setImmutableUtmCookieImmutablePropType, universalCookieExpressOrOtherCookiePropType$1 as universalCookieExpressOrOtherCookiePropType, utmCookieImmutablePropType$1 as utmCookieImmutablePropType, utmCookiePropType$1 as utmCookiePropType };
